import "./Main.scss";

import { ButtonSizes, ButtonText, Urls } from "../../constants";
import { Currencies, Regions } from "../../types";
import { FunctionComponent, ReactElement } from "react";
import { getCurrencySymbol, getCurrentPrice } from "../../utils/DevicePrice";

import { BuyNowButton } from "../../components/Button/BuyNowButton";
import DeviceImage from "../../img/station-p1-pro.png";

interface Props {
  region?: Regions;
  currency?: Currencies;
}

export const Main: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { region, currency } = props;
  const currencySymbol = getCurrencySymbol(currency);
  const price = getCurrentPrice(region, currency);

  return (
    <div className="main-container dark">
      <div className="main-content-wrapper">
        <div className="main-text-container">
          <div className="sora bold-weight xxxl-size main-text-heading">
            {"The Station P1 Pro powered by ScreenCloud OS"}
          </div>
          <div className="sora bold-weight l-size price">
            {currencySymbol}
            {price} per device
          </div>
          <div className="lato dark-gray-color m-size main-text">
            ScreenCloud OS is a purpose-built operating system designed to
            deliver amazing performance and enterprise-grade features at the
            most affordable price. And this device comes with all the
            specifications and features needed to deliver on that promise,
            making it perfect for easy set up and scaling of your digital
            signage.
          </div>
          {region && (
            <BuyNowButton
              size={ButtonSizes.Medium}
              currency={currency}
              region={region}
            >
              {ButtonText.BuyNow}
            </BuyNowButton>
          )}
          <div className="lato dark-gray-color medium-weight xs-size main-text-footer">
            Need more than 25 devices?{" "}
            <a className="dark-gray-color" href={Urls.ContactSales}>
              Contact Sales for assistance
            </a>
          </div>
        </div>
        <img src={DeviceImage} alt={"device"} className="main-device-image" />
      </div>
    </div>
  );
};
