import { Currencies, CurrencySymbols, Price, Regions } from "../types";

export const getCurrencySymbol = (currency?: Currencies): CurrencySymbols => {
  return CurrencySymbols[currency || Currencies.DEFAULT];
};

export const getCurrentPrice = (
  region?: Regions,
  currency?: Currencies
): Price => {
  if (!region && !currency) {
    return Price.USD;
  }
  return currency === Currencies.GBP // Country uses GBP
    ? Price.GBP
    : // Country uses EUR
    currency === Currencies.EUR
    ? Price.EUR
    : // Country USA or Canada
    region === Regions.US
    ? Price.USD
    : // Rest of world
      Price.ROW;
};
