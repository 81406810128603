import { FunctionComponent, ReactElement, useState } from "react";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import "./Modal.scss";
import { Currencies, Regions } from "../../types";
import { DEFAULT_REGION } from "../../constants";

interface Props {
  onSubmit: (currency: Currencies, region: Regions) => void;
}

export const Modal: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onSubmit } = props;
  const [chargebee, setDetails] = useState({
    currency: Currencies.DEFAULT,
    region: DEFAULT_REGION,
  });
  return (
    <>
      <div className="dimmer" />
      <div className="modal-background">
        <div className="modal-container light-color">
          <div className="sora bold-weight xxxl-size">
            Please select your country
          </div>
          <div className="button-group">
            <Dropdown
              onSelect={(currency: Currencies, region: Regions) => {
                setDetails({ currency, region });
              }}
            />
            <Button
              active
              onClick={() => onSubmit(chargebee.currency, chargebee.region)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
