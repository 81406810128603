import { FunctionComponent, ReactElement, useState } from "react";
import { ReactComponent as Triangle } from "../../img/triangle.svg";
import { Currencies, Option, Regions } from "../../types";
import { DROPDOWN_OPTIONS } from "./CountryOptions";
import "./Dropdown.scss";
import { US_COUNTRIES } from "../../constants";

interface Props {
  onSelect: (currency: Currencies, region: Regions) => void;
}

export const Dropdown: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { onSelect } = props;
  const options = DROPDOWN_OPTIONS;
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Option>(options[0]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (option: Option) => {
    setSelectedItem(option);
    onSelect(
      option.currency,
      US_COUNTRIES.includes(option.label) ? Regions.US : Regions.EU
    );
    toggleDropdown();
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedItem
          ? options.find((options) => options.label === selectedItem.label)
              ?.label
          : "Select your destination"}
        <Triangle className={`icon ${isOpen && "open"}`} />
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {options.map((option) => (
          <div
            className="dropdown-item"
            onClick={(e) => handleItemClick(option)}
            id={option.label.toString()}
            key={option.label}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
