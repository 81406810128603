import { FunctionComponent, ReactElement } from "react";
import { ReactComponent as Shape_1 } from "../../img/shape-1.svg";
import { ReactComponent as Shape_2 } from "../../img/shape-2.svg";
import { ReactComponent as Shape_3 } from "../../img/shape-3.svg";
import DeviceImage from "../../img/scos-device-2.jpg";
import { Card } from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import { ButtonSizes, Urls } from "../../constants";
import "./Features.scss";

export const Features: FunctionComponent = (): ReactElement => {
  return (
    <div className="features-container light-color">
      <div className="cards-container">
        <Card icon={Shape_1} heading="Greater performance">
          <CardBody>
            The Station P1 Pro device powered by ScreenCloud OS comes with a
            six-core CPU, a quad-core GPU and 4GB of RAM, capable of running
            even demanding content. And there&apos;s also 32GB of storage so
            your cached content can continue to play even when your internet
            connection becomes unstable.
          </CardBody>
        </Card>
        <Card icon={Shape_2} heading="Fast multi-screen deployment">
          <CardBody>
            Remove all complexity with our plug-in-and-play approach.
            ScreenCloud OS runs on the high-performing Station P1 Pro device,
            pre-installed with ScreenCloud&apos;s software for streamlined
            deployment in minutes.
          </CardBody>
        </Card>
        <Card
          icon={Shape_3}
          heading="Manage your digital signage fleet remotely"
        >
          <CardBody>
            With free, built-in remote device management features, ScreenCloud
            OS can help you avoid in person visits for your IT teams with the
            ability to do things like remotely reboot devices, update network
            settings or credentials and more.
          </CardBody>
        </Card>
      </div>
      <div className="panel">
        <img src={DeviceImage} alt="device" className="panel-image" />
        <div className="panel-section">
          <div className="panel-text">
            <div className="sora bold-weight xl-size panel-text-heading">
              Easier. Faster. More affordable.
            </div>
            <div className="lato gray-color s-size medium-weight panel-text-body">
              A new digital signage operating system made for ScreenCloud, by
              ScreenCloud.
            </div>
          </div>
          <Button
            className="panel-button"
            size={ButtonSizes.Medium}
            onClick={() => {
              window.open(Urls.FindOutMore, "_blank")?.focus();
            }}
            active
          >
            Find Out More
          </Button>
        </div>
      </div>
    </div>
  );
};

const CardBody: FunctionComponent = ({ children }): ReactElement => {
  return <div className="lato gray-color medium-weight s-size">{children}</div>;
};
