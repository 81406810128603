import { Regions } from "./types";

export enum ButtonSizes {
  Normal = "normal",
  Medium = "medium",
}

export enum ButtonText {
  BuyNow = "Buy Now",
  ContactSales = "Contact Sales",
}

export enum Urls {
  ContactSales = "https://screencloud.com/contact",
  FindOutMore = "https://screencloud.com/screencloud-os",
  P1ProInfo = "https://support.screencloud.com/hc/en-gb/articles/6564099092381",
}

export const DEFAULT_REGION = Regions.US;
export const ADDON_US_REGION = "screencloud-os---usd---2024";
export const ADDON_EU_REGION_GBP = "screencloud-os-(gbp)---2024";
export const ADDON_EU_REGION_USD = "screencloud-os-(usd)---2024";
export const ADDON_EU_REGION_EUR = "screencloud-os-(eur)---2024";

/**
 * Countries that get sent to US sign up
 */
export const US_COUNTRIES = [
  "Antigua And Barbuda",
  "Argentina",
  "Bahamas",
  "Barbados",
  "Belize",
  "Bolivia",
  "Brazil",
  "Canada",
  "Chile",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "El Salvador",
  "Grenada",
  "Guatemala",
  "Guyana",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Mexico",
  "Nicaragua",
  "Panama",
  "Paraguay",
  "Peru",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Surilabel",
  "Trinidad and Tobago",
  "United States of America",
  "Uruguay",
  "Venezuela",
  "French Guiana",
  "Falkland Islands (Malvinas)",
];
