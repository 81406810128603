import Icon_1 from "../../img/whats-included-1.svg";
import Icon_2 from "../../img/whats-included-2.svg";
import Icon_3 from "../../img/whats-included-3.svg";
import Icon_4 from "../../img/whats-included-4.svg";
import Icon_5 from "../../img/whats-included-5.svg";

const Tab = () => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

export const gadgetList = [
  { icon: <img src={Icon_1} alt="icon"></img>, text: "Station P1 Pro Device" },
  {
    icon: <img src={Icon_2} alt="icon"></img>,
    text: "WiFi Antenna",
  },
  {
    icon: <img src={Icon_3} alt="icon"></img>,
    text: "Power Supply",
  },
  {
    icon: <img src={Icon_4} alt="icon"></img>,
    text: "HDMI Cable",
  },
  {
    icon: <img src={Icon_5} alt="icon"></img>,
    text: "L-Shaped Allen Wrench",
  },
];

export const specList = [
  { heading: "SOC", body: "Rockchip RK3399" },
  {
    heading: "CPU",
    body: (
      <>
        <div>
          Six-core 64-bit processor (ARM dual-core Cortex-A72 + quad-core
          Cortex-A53)
        </div>
        <div>Frequency up to 1.8GHz</div>
      </>
    ),
  },
  { heading: "GPU", body: "Quad-core GPU (Mali-T860 MP4)" },
  { heading: "RAM", body: "4GB LPDDR4 RAM" },
  { heading: "Storage", body: "32GB eMMC storage (onboard)" },
  {
    heading: "Display output",
    body: (
      <>
        <div>1 x HDMI 2.0a</div>
        <div>Supports up to 4K output</div>
      </>
    ),
  },
  {
    heading: "WiFi",
    body: (
      <>
        <div>
          2.4GHz / 5GHz dual band WiFi, supports 802.11 a/b/g/n/ac protocol
        </div>
        <div>3db WiFi antenna</div>
      </>
    ),
  },
  {
    heading: "Security Support - WiFi",
    body: (
      <>
        <div>
          <b>No Authentication</b>
        </div>
        <div>
          <b>Basic Authentication</b>
        </div>
        <div className="indent-one">WPA2-PSK{"\n"}</div>
        <div className="indent-two">a. Password{"\n"}</div>
        <div>
          <b>Enterprise Authentication</b>
        </div>
        <div className="indent-one">WPA2-Enterprise (TLS)</div>
        <div className="indent-two">a. Identity</div>
        <div className="indent-two">b. Password</div>
        <div className="indent-one">WPA2-Enterprise (Protected EAP - PEAP)</div>
        <div className="indent-two">a. Identity</div>
        <div className="indent-two">b. Password</div>
        <br />
        (Hidden networks are also supported for all of the above network types)
      </>
    ),
  },
  {
    heading: "Ethernet",
    body: (
      <>
        <div>1 x 10/100/1000Mbps Gigabit Ethernet</div>
        <div>(RJ45 interface)</div>
      </>
    ),
  },
  { heading: "Security Support - Ethernet", body: <b>No Authentication</b> },
  {
    heading: "Other Advanced Configurations Support - WiFi & Ethernet",
    body: (
      <>
        <div>
          <b>Proxy</b>
        </div>
        <div>
          <Tab />
          a. Host
        </div>
        <div>
          <Tab />
          b. Port
        </div>
        <div>
          <b>NTP (Custom)</b>
        </div>
        <div>
          <Tab />
          a. NTP (multiple entries supported)
        </div>
        <div>
          <b>Certificates</b>
        </div>
        <div>
          <Tab />
          a. Import
        </div>
      </>
    ),
  },
  { heading: "Currently Not Supported - Ethernet", body: <b>IEEE 802.1X</b> },
  {
    heading: "I/O",
    body: (
      <>
        <div>1 x USB 2.0</div>
        <div>1 x USB 3.0</div>
        <div>1 x USB-C (USB3.0)</div>
        <div>1 x Audio (3.5mm)</div>
      </>
    ),
  },
  {
    heading: "Power input",
    body: (
      <>
        <div>DC 12V-2A (5.5*2.1mm)</div>
        <div>Supports 9-12V wide voltage input </div>
      </>
    ),
  },

  {
    heading: "Power consumption",
    body: (
      <>
        <div>Idle: 0.12W</div>
        <div>Normal: 3.6W</div>
        <div>Max: 9.6W</div>
      </>
    ),
  },
  {
    heading: "Size and Weight",
    body: (
      <>
        <div>Height: 1.22 inches (3.1 cm)</div>
        <div>Width: 3.11 inches (7.9 cm)</div>
        <div>Depth: 4.88 inches (12.4 cm)</div>
        <div>Weight: 0.8 pounds (365g)</div>
      </>
    ),
  },
  {
    heading: "Operating environment",
    body: (
      <>
        <div>Operating Temperature: -20°C - 60°C</div>
        <div>Storage Temperature: -20°C - 70°C</div>
        <div>Storage Humidity: 10% - 80 %</div>
      </>
    ),
  },
];
