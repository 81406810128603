import {
  ADDON_EU_REGION_EUR,
  ADDON_EU_REGION_GBP,
  ADDON_EU_REGION_USD,
  ADDON_US_REGION,
} from "../constants";
import { Currencies, Regions } from "../types";

/**
 * Determine what SCOS device addon the customer should purchase
 * This will depend on the Chargebee region and currency.
 *
 * @param region Chargebee region - US (USA and Canada) or EU (UK and Rest of world)
 * @param currency Supported currency for the country
 */
export const getAddonId = (region?: Regions, currency?: Currencies): string => {
  if (region === Regions.US) {
    return ADDON_US_REGION;
  }
  if (currency === Currencies.GBP) {
    return ADDON_EU_REGION_GBP;
  }
  if (currency === Currencies.EUR) {
    return ADDON_EU_REGION_EUR;
  }
  return ADDON_EU_REGION_USD;
};
