import { FunctionComponent, ReactElement } from "react";
import { Gadget } from "../../components/Gadget/Gadget";
import { gadgetList, specList } from "./content";
import "./ProductSpecs.scss";

const generateGridRow = (
  heading: string,
  body: string | JSX.Element,
  index: number
): ReactElement => {
  return (
    <div key={index}>
      <div className="sora medium-weight xxs-size tech-specs-grid-heading">
        {heading}
      </div>
      <div className="lato gray-color medium-weight s-size tech-specs-grid-body">
        {body}
      </div>
    </div>
  );
};

export const ProductSpecs: FunctionComponent = (): ReactElement => {
  return (
    <div className="specs-container light-color">
      <div className="specs-content-wrapper">
        <div className="sora bold-weight xxl-size">Product Information</div>
        <div className="specs-grid">
          <div className="sora bold-weight l-size specs-heading">General</div>
          <div className="lato gray-color medium-weight s-size specs-body">
            ScreenCloud OS lets you focus on what really matters. Built by
            digital signage experts, ScreenCloud OS is the industry&apos;s
            newest purpose-built digital signage operating system and device.
            Its all-in-one solution makes it one of the most powerful and
            cost-effective options on the market.
          </div>
          <div className="sora bold-weight l-size specs-heading">
            What&apos;s included
          </div>
          <div className="gadgets-grid">
            {gadgetList.map((gadget, key) => {
              return (
                <Gadget icon={gadget.icon} caption={gadget.text} key={key} />
              );
            })}
          </div>
          <div className="sora bold-weight l-size specs-heading">
            Specifications / supported features
          </div>
          <div className="tech-specs-grid">
            {specList.map((spec, index) => {
              return generateGridRow(spec.heading, spec.body, index);
            })}
          </div>
          <div className="sora bold-weight l-size specs-heading requirements">
            Requirements
          </div>
          <div className="lato gray-color medium-weight s-size specs-body">
            <div>Valid ScreenCloud subscription or trial</div>
            <div>Internet connection</div>
          </div>
          <div className="sora bold-weight l-size specs-heading warranty">
            Warranty
          </div>
          <div className="lato gray-color medium-weight s-size specs-body">
            1 year
          </div>
        </div>
      </div>
    </div>
  );
};
