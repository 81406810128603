import { FunctionComponent, ReactElement } from "react";
import { ButtonSizes } from "../../constants";
import "./Button.scss";

interface Props {
  active?: boolean;
  size?: ButtonSizes;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

export const Button: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { active, size, onClick, children } = props;
  return (
    <>
      <button
        className={`button ${active ? "active" : ""} ${size || "normal"} ${
          props.className || ""
        }`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};
