export enum Regions {
  US = "us",
  EU = "eu",
}

export enum Currencies {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  DEFAULT = "USD",
}

export enum CurrencySymbols {
  USD = "$",
  EUR = "€",
  GBP = "£",
}

// Matches prices laid out here: https://docs.google.com/spreadsheets/d/1lcVhPdNJ8xpw613k7maNXS0ILosyaU-d9-Rr0I2wxf8/edit#gid=0
export enum Price {
  USD = "150",
  GBP = "120",
  EUR = "140",
  ROW = "150",
}

export interface Option {
  label: string;
  currency: Currencies;
}

declare global {
  interface Window {
    Chargebee: {
      // https://www.chargebee.com/checkout-portal-docs/api.html#init
      init: (options: { site: string; enableGTMTracking: boolean, isItemsModel?: boolean; }) => void;
      // https://www.chargebee.com/checkout-portal-docs/dropIn.html#registeragain
      registerAgain: () => void;
      getInstance: () => void;
    };
  }
}
