import Logo from "../../img/logo-2.png";
import { FunctionComponent, ReactElement } from "react";
import "./Footer.scss";

export const Footer: FunctionComponent = (): ReactElement => {
  return (
    <div className="footer-container">
      <img src={Logo} alt="logo" />
    </div>
  );
};
