import { FunctionComponent, ReactElement } from "react";
import DeviceImage from "../../img/scos-device-new.jpg";
import { Button } from "../../components/Button/Button";
import { ButtonSizes, Urls } from "../../constants";
import "./Compare.scss";

export const Compare: FunctionComponent = (): ReactElement => {
  return (
    <div className="compare-container light-color">
      <div className="panel">
        <img src={DeviceImage} alt="device" className="panel-image" />
        <div className="panel-section">
          <div className="panel-text">
            <div className="sora bold-weight xl-size panel-text-heading">
              New, updated hardware.
            </div>
            <div className="lato gray-color s-size medium-weight panel-text-body">
              The Station P1 Pro replaces the previous Station P1, with a
              refined design and improved WiFi capabilities.
            </div>
          </div>
          <Button
            className="panel-button"
            size={ButtonSizes.Medium}
            onClick={() => {
              window.open(Urls.P1ProInfo, "_blank")?.focus();
            }}
            active
          >
            Find Out More
          </Button>
        </div>
      </div>
    </div>
  );
};
