import { Currencies, Option } from "../../types";

export const DROPDOWN_OPTIONS: Option[] = [
  { label: "United States of America", currency: Currencies.DEFAULT },
  { label: "Aland Islands", currency: Currencies.DEFAULT },
  { label: "Albania", currency: Currencies.DEFAULT },
  { label: "Algeria", currency: Currencies.DEFAULT },
  { label: "American Samoa", currency: Currencies.DEFAULT },
  { label: "Andorra", currency: Currencies.EUR },
  { label: "Angola", currency: Currencies.DEFAULT },
  { label: "Anguilla", currency: Currencies.DEFAULT },
  { label: "Antarctica", currency: Currencies.DEFAULT },
  { label: "Antigua And Barbuda", currency: Currencies.DEFAULT },
  { label: "Argentina", currency: Currencies.DEFAULT },
  { label: "Armenia", currency: Currencies.DEFAULT },
  { label: "Aruba", currency: Currencies.DEFAULT },
  { label: "Australia", currency: Currencies.DEFAULT },
  { label: "Austria", currency: Currencies.EUR },
  { label: "Azerbaijan", currency: Currencies.DEFAULT },
  { label: "Bahamas", currency: Currencies.DEFAULT },
  { label: "Bahrain", currency: Currencies.DEFAULT },
  { label: "Bangladesh", currency: Currencies.DEFAULT },
  { label: "Barbados", currency: Currencies.DEFAULT },
  { label: "Belarus", currency: Currencies.DEFAULT },
  { label: "Belgium", currency: Currencies.EUR },
  { label: "Belize", currency: Currencies.DEFAULT },
  { label: "Benin", currency: Currencies.DEFAULT },
  { label: "Bermuda", currency: Currencies.DEFAULT },
  { label: "Bhutan", currency: Currencies.DEFAULT },
  { label: "Bolivia", currency: Currencies.DEFAULT },
  { label: "Bosnia And Herzegovina", currency: Currencies.DEFAULT },
  { label: "Botswana", currency: Currencies.DEFAULT },
  { label: "Bouvet Island", currency: Currencies.DEFAULT },
  { label: "Brazil", currency: Currencies.DEFAULT },
  { label: "British Indian Ocean Territory", currency: Currencies.DEFAULT },
  { label: "British Virgin Islands", currency: Currencies.DEFAULT },
  { label: "Brunei Darussalam", currency: Currencies.DEFAULT },
  { label: "Bulgaria", currency: Currencies.DEFAULT },
  { label: "Burkina Faso", currency: Currencies.DEFAULT },
  { label: "Burundi", currency: Currencies.DEFAULT },
  { label: "Cambodia", currency: Currencies.DEFAULT },
  { label: "Cameroon", currency: Currencies.DEFAULT },
  { label: "Canada", currency: Currencies.DEFAULT },
  { label: "Cape Verde", currency: Currencies.DEFAULT },
  { label: "Cayman Islands", currency: Currencies.DEFAULT },
  { label: "Central African Republic", currency: Currencies.DEFAULT },
  { label: "Chad", currency: Currencies.DEFAULT },
  { label: "Chile", currency: Currencies.DEFAULT },
  { label: "China", currency: Currencies.DEFAULT },
  { label: "Christmas Island", currency: Currencies.DEFAULT },
  { label: "Cocos (Keeling) Islands", currency: Currencies.DEFAULT },
  { label: "Colombia", currency: Currencies.DEFAULT },
  { label: "Comoros", currency: Currencies.DEFAULT },
  { label: "Congo", currency: Currencies.DEFAULT },
  { label: "Congo (Democratic Republic)", currency: Currencies.DEFAULT },
  { label: "Cook Islands", currency: Currencies.DEFAULT },
  { label: "Costa Rica", currency: Currencies.DEFAULT },
  { label: "Côte D'ivoire", currency: Currencies.DEFAULT },
  { label: "Croatia", currency: Currencies.EUR },
  { label: "Curaçao", currency: Currencies.DEFAULT },
  { label: "Cuba", currency: Currencies.DEFAULT },
  { label: "Cyprus", currency: Currencies.EUR },
  { label: "Czech Republic", currency: Currencies.DEFAULT },
  { label: "Denmark", currency: Currencies.DEFAULT },
  { label: "Djibouti", currency: Currencies.DEFAULT },
  { label: "Dominica", currency: Currencies.DEFAULT },
  { label: "Dominican Republic", currency: Currencies.DEFAULT },
  { label: "Ecuador", currency: Currencies.DEFAULT },
  { label: "Egypt", currency: Currencies.DEFAULT },
  { label: "El Salvador", currency: Currencies.DEFAULT },
  { label: "Equatorial Guinea", currency: Currencies.DEFAULT },
  { label: "Eritrea", currency: Currencies.DEFAULT },
  { label: "Estonia", currency: Currencies.EUR },
  { label: "Ethiopia", currency: Currencies.DEFAULT },
  { label: "Falkland Islands (Malvinas)", currency: Currencies.DEFAULT },
  { label: "Faroe Islands", currency: Currencies.DEFAULT },
  { label: "Fiji", currency: Currencies.DEFAULT },
  { label: "Finland", currency: Currencies.EUR },
  { label: "France", currency: Currencies.EUR },
  { label: "French Guiana", currency: Currencies.DEFAULT },
  { label: "French Polynesia", currency: Currencies.DEFAULT },
  { label: "French Southern Territories", currency: Currencies.DEFAULT },
  { label: "Gabon", currency: Currencies.DEFAULT },
  { label: "Gambia", currency: Currencies.DEFAULT },
  { label: "Georgia", currency: Currencies.DEFAULT },
  { label: "Germany", currency: Currencies.EUR },
  { label: "Ghana", currency: Currencies.DEFAULT },
  { label: "Gibraltar", currency: Currencies.DEFAULT },
  { label: "Greece", currency: Currencies.EUR },
  { label: "Greenland", currency: Currencies.DEFAULT },
  { label: "Grenada", currency: Currencies.DEFAULT },
  { label: "Guadeloupe", currency: Currencies.DEFAULT },
  { label: "Guam", currency: Currencies.DEFAULT },
  { label: "Guatemala", currency: Currencies.DEFAULT },
  { label: "Guernsey", currency: Currencies.DEFAULT },
  { label: "Guinea", currency: Currencies.DEFAULT },
  { label: "Guinea-bissau", currency: Currencies.DEFAULT },
  { label: "Guyana", currency: Currencies.DEFAULT },
  { label: "Haiti", currency: Currencies.DEFAULT },
  { label: "Heard Island And Mcdonald Islands", currency: Currencies.DEFAULT },
  { label: "Honduras", currency: Currencies.DEFAULT },
  { label: "Hong Kong", currency: Currencies.DEFAULT },
  { label: "Hungary", currency: Currencies.DEFAULT },
  { label: "Iceland", currency: Currencies.DEFAULT },
  { label: "India", currency: Currencies.DEFAULT },
  { label: "Indonesia", currency: Currencies.DEFAULT },
  { label: "Iran", currency: Currencies.DEFAULT },
  { label: "Iraq", currency: Currencies.DEFAULT },
  { label: "Ireland", currency: Currencies.EUR },
  { label: "Isle Of Man", currency: Currencies.DEFAULT },
  { label: "Israel", currency: Currencies.DEFAULT },
  { label: "Italy", currency: Currencies.EUR },
  { label: "Jamaica", currency: Currencies.DEFAULT },
  { label: "Japan", currency: Currencies.DEFAULT },
  { label: "Jersey", currency: Currencies.DEFAULT },
  { label: "Jordan", currency: Currencies.DEFAULT },
  { label: "Kazakhstan", currency: Currencies.DEFAULT },
  { label: "Kenya", currency: Currencies.DEFAULT },
  { label: "Kiribati", currency: Currencies.DEFAULT },
  {
    label: "Korea (Democratic People's Republic)",
    currency: Currencies.DEFAULT,
  },
  { label: "Korea (Republic)", currency: Currencies.DEFAULT },
  { label: "Kuwait", currency: Currencies.DEFAULT },
  { label: "Kyrgyzstan", currency: Currencies.DEFAULT },
  { label: "Lao (People's Democratic Republic)", currency: Currencies.DEFAULT },
  { label: "Latvia", currency: Currencies.EUR },
  { label: "Lebanon", currency: Currencies.DEFAULT },
  { label: "Lesotho", currency: Currencies.DEFAULT },
  { label: "Liberia", currency: Currencies.DEFAULT },
  { label: "Libya", currency: Currencies.DEFAULT },
  { label: "Liechtenstein", currency: Currencies.DEFAULT },
  { label: "Lithuania", currency: Currencies.EUR },
  { label: "Luxembourg", currency: Currencies.EUR },
  { label: "Macao", currency: Currencies.DEFAULT },
  { label: "Macedonia", currency: Currencies.DEFAULT },
  { label: "Madagascar", currency: Currencies.DEFAULT },
  { label: "Malawi", currency: Currencies.DEFAULT },
  { label: "Malaysia", currency: Currencies.DEFAULT },
  { label: "Maldives", currency: Currencies.DEFAULT },
  { label: "Mali", currency: Currencies.DEFAULT },
  { label: "Malta", currency: Currencies.EUR },
  { label: "Marshall Islands", currency: Currencies.DEFAULT },
  { label: "Martinique", currency: Currencies.DEFAULT },
  { label: "Mauritania", currency: Currencies.DEFAULT },
  { label: "Mauritius", currency: Currencies.DEFAULT },
  { label: "Mayotte", currency: Currencies.DEFAULT },
  { label: "Mexico", currency: Currencies.DEFAULT },
  { label: "Micronesia", currency: Currencies.DEFAULT },
  { label: "Moldova", currency: Currencies.DEFAULT },
  { label: "Monaco", currency: Currencies.EUR },
  { label: "Mongolia", currency: Currencies.DEFAULT },
  { label: "Montenegro", currency: Currencies.DEFAULT },
  { label: "Montserrat", currency: Currencies.DEFAULT },
  { label: "Morocco", currency: Currencies.DEFAULT },
  { label: "Mozambique", currency: Currencies.DEFAULT },
  { label: "Myanmar", currency: Currencies.DEFAULT },
  { label: "Namibia", currency: Currencies.DEFAULT },
  { label: "Nauru", currency: Currencies.DEFAULT },
  { label: "Nepal", currency: Currencies.DEFAULT },
  { label: "Netherlands", currency: Currencies.EUR },
  { label: "New Caledonia", currency: Currencies.DEFAULT },
  { label: "New Zealand", currency: Currencies.DEFAULT },
  { label: "Nicaragua", currency: Currencies.DEFAULT },
  { label: "Niger", currency: Currencies.DEFAULT },
  { label: "Nigeria", currency: Currencies.DEFAULT },
  { label: "Niue", currency: Currencies.DEFAULT },
  { label: "Norfolk Island", currency: Currencies.DEFAULT },
  { label: "Northern Mariana Islands", currency: Currencies.DEFAULT },
  { label: "Norway", currency: Currencies.DEFAULT },
  { label: "Oman", currency: Currencies.DEFAULT },
  { label: "Pakistan", currency: Currencies.DEFAULT },
  { label: "Palau", currency: Currencies.DEFAULT },
  { label: "Palestinian Territory (Occupied)", currency: Currencies.DEFAULT },
  { label: "Panama", currency: Currencies.DEFAULT },
  { label: "Papua New Guinea", currency: Currencies.DEFAULT },
  { label: "Paraguay", currency: Currencies.DEFAULT },
  { label: "Peru", currency: Currencies.DEFAULT },
  { label: "Philippines", currency: Currencies.DEFAULT },
  { label: "Pitcairn", currency: Currencies.DEFAULT },
  { label: "Poland", currency: Currencies.DEFAULT },
  { label: "Portugal", currency: Currencies.EUR },
  { label: "Puerto Rico", currency: Currencies.DEFAULT },
  { label: "Qatar", currency: Currencies.DEFAULT },
  { label: "Reunion", currency: Currencies.DEFAULT },
  { label: "Romania", currency: Currencies.DEFAULT },
  { label: "Russian Federation", currency: Currencies.DEFAULT },
  { label: "Rwanda", currency: Currencies.DEFAULT },
  { label: "Saint Barthélemy", currency: Currencies.DEFAULT },
  { label: "Saint Helena", currency: Currencies.DEFAULT },
  { label: "Saint Kitts And Nevis", currency: Currencies.DEFAULT },
  { label: "Saint Lucia", currency: Currencies.DEFAULT },
  { label: "Saint Martin (French Part)", currency: Currencies.DEFAULT },
  { label: "Saint Pierre And Miquelon", currency: Currencies.DEFAULT },
  { label: "Saint Vincent And The Grenadines", currency: Currencies.DEFAULT },
  { label: "Samoa", currency: Currencies.DEFAULT },
  { label: "San Marino", currency: Currencies.EUR },
  { label: "Sao Tome And Principe", currency: Currencies.DEFAULT },
  { label: "Saudi Arabia", currency: Currencies.DEFAULT },
  { label: "Senegal", currency: Currencies.DEFAULT },
  { label: "Serbia", currency: Currencies.DEFAULT },
  { label: "Seychelles", currency: Currencies.DEFAULT },
  { label: "Sierra Leone", currency: Currencies.DEFAULT },
  { label: "Singapore", currency: Currencies.DEFAULT },
  { label: "Sint Eustatius And Saba Bonaire", currency: Currencies.DEFAULT },
  { label: "Sint Maarten (Dutch Part)", currency: Currencies.DEFAULT },
  { label: "Slovakia", currency: Currencies.EUR },
  { label: "Slovenia", currency: Currencies.EUR },
  { label: "Solomon Islands", currency: Currencies.DEFAULT },
  { label: "Somalia", currency: Currencies.DEFAULT },
  { label: "South Africa", currency: Currencies.DEFAULT },
  {
    label: "South Georgia And The South Sandwich Islands",
    currency: Currencies.DEFAULT,
  },
  { label: "South Sudan", currency: Currencies.DEFAULT },
  { label: "Spain", currency: Currencies.EUR },
  { label: "Sri Lanka", currency: Currencies.DEFAULT },
  { label: "Sudan", currency: Currencies.DEFAULT },
  { label: "Suriname", currency: Currencies.DEFAULT },
  { label: "Svalbard And Jan Mayen", currency: Currencies.DEFAULT },
  { label: "Swaziland", currency: Currencies.DEFAULT },
  { label: "Sweden", currency: Currencies.DEFAULT },
  { label: "Switzerland", currency: Currencies.DEFAULT },
  { label: "Syrian Arab Republic", currency: Currencies.DEFAULT },
  { label: "Taiwan, Province Of China", currency: Currencies.DEFAULT },
  { label: "Tajikistan", currency: Currencies.DEFAULT },
  { label: "Tanzania", currency: Currencies.DEFAULT },
  { label: "Thailand", currency: Currencies.DEFAULT },
  { label: "Timor-leste", currency: Currencies.DEFAULT },
  { label: "Togo", currency: Currencies.DEFAULT },
  { label: "Tokelau", currency: Currencies.DEFAULT },
  { label: "Tonga", currency: Currencies.DEFAULT },
  { label: "Trinidad And Tobago", currency: Currencies.DEFAULT },
  { label: "Tunisia", currency: Currencies.DEFAULT },
  { label: "Turkey", currency: Currencies.DEFAULT },
  { label: "Turkmenistan", currency: Currencies.DEFAULT },
  { label: "Turks And Caicos Islands", currency: Currencies.DEFAULT },
  { label: "Tuvalu", currency: Currencies.DEFAULT },
  { label: "Uganda", currency: Currencies.DEFAULT },
  { label: "Ukraine", currency: Currencies.DEFAULT },
  { label: "United Arab Emirates", currency: Currencies.DEFAULT },
  { label: "United Kingdom", currency: Currencies.GBP },
  {
    label: "United States Minor Outlying Islands",
    currency: Currencies.DEFAULT,
  },
  { label: "Uruguay", currency: Currencies.DEFAULT },
  { label: "Uzbekistan", currency: Currencies.DEFAULT },
  { label: "Vanuatu", currency: Currencies.DEFAULT },
  { label: "Vatican City", currency: Currencies.EUR },
  { label: "Venezuela", currency: Currencies.DEFAULT },
  { label: "Viet Nam", currency: Currencies.DEFAULT },
  { label: "U.S. Virgin Islands", currency: Currencies.DEFAULT },
  { label: "Wallis And Futuna", currency: Currencies.DEFAULT },
  { label: "Western Sahara", currency: Currencies.DEFAULT },
  { label: "Yemen", currency: Currencies.DEFAULT },
  { label: "Zambia", currency: Currencies.DEFAULT },
  { label: "Zimbabwe", currency: Currencies.DEFAULT },
];
