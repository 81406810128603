import { FunctionComponent, ReactElement, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { Modal } from "../../components/Modal/Modal";
import { Currencies, Regions } from "../../types";
import { Features } from "../Features/Features";
import { Compare } from "../Compare/Compare";
import { Main } from "../Main/Main";
import { ProductSpecs } from "../ProductSpecs/ProductSpecs";
import "./AppContainer.scss";

export const AppContainer: FunctionComponent = (): ReactElement => {
  const [chargebee, setChargebeeDetails] = useState<
    | {
        currency: Currencies;
        region: Regions;
      }
    | undefined
  >(undefined);

  return (
    <>
      {!chargebee?.region && (
        <Modal
          onSubmit={(currency: Currencies, region: Regions) => {
            setChargebeeDetails({ currency, region });
          }}
        />
      )}
      <Header region={chargebee?.region} currency={chargebee?.currency} />
      <Main region={chargebee?.region} currency={chargebee?.currency} />
      <Features />
      <ProductSpecs />
      <Compare />
      <Footer />
    </>
  );
};
