import { FunctionComponent, ReactElement } from "react";
import "./Header.scss";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { ButtonText } from "../../constants";
import { getCurrencySymbol, getCurrentPrice } from "../../utils/DevicePrice";
import { Currencies, Regions } from "../../types";
import { BuyNowButton } from "../Button/BuyNowButton";

interface Props {
  region?: Regions;
  currency?: Currencies;
}

export const Header: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { region, currency } = props;
  const currencySymbol = getCurrencySymbol(currency);
  const devicePrice = getCurrentPrice(region, currency);

  return (
    <div className="header-container">
      <div className="header-content-wrapper">
        <Logo />
        <div className="button-group">
          <div className="sora dark-gray-color medium-weight xxs-size price sm-hide">
            {currencySymbol}
            {devicePrice} per device
          </div>
          {region && (
            <BuyNowButton active region={region} currency={currency}>
              {ButtonText.BuyNow}
            </BuyNowButton>
          )}
        </div>
      </div>
    </div>
  );
};
