import { FunctionComponent, ReactElement } from "react";
import "./Card.scss";

interface Props {
  icon: FunctionComponent;
  heading: string;
  children: React.ReactNode;
}

export const Card: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { icon: CardIcon, heading } = props;
  return (
    <div className="card-container">
      <div className="card-icon-wrapper">
        <CardIcon />
      </div>
      <div className="sora light-color bold-weight l-size card-heading">
        {heading}
      </div>
      {props.children}
    </div>
  );
};
