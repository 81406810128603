import { FunctionComponent, ReactElement } from "react";
import "./Gadget.scss";

interface Props {
  icon: JSX.Element;
  caption: string;
}

export const Gadget: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { icon: Icon, caption } = props;

  return (
    <div className="gadget-container">
      <div className="gadget-icon-wrapper">{Icon}</div>
      <div className="sora light-gray-color medium-weight xxs-size caption">
        {caption}
      </div>
    </div>
  );
};
