import { FunctionComponent, ReactElement, useEffect } from "react";
import { ButtonSizes } from "../../constants";
import { Currencies, Regions } from "../../types";
import "./Button.scss";
import { getAddonId } from "../../utils/Addons";

interface Props {
  active?: boolean;
  size?: ButtonSizes;
  children: React.ReactNode;
  className?: string;
  region?: Regions;
  currency?: Currencies;
}

export const BuyNowButton: FunctionComponent<Props> = (
  props: Props
): ReactElement => {
  const { active, size, children, region, currency } = props;

  // Check what addon the customer should purchase
  const addonId = getAddonId(region, currency);

  const site =
    (region === Regions.EU
      ? process.env.REACT_APP_CHARGEBEE_EU_ACCOUNT
      : process.env.REACT_APP_CHARGEBEE_ACCOUNT) || "";

  useEffect(() => {
    // Ensure that Chargbee script has been loaded by the script in index.html
    if (window.Chargebee) {
      try {
        // On first load initialize Chargebee with the correct Chargebee site
        // Two buttons on screen means the second will cause a warn in console.
        // We could check if Chargebee.getInstance returns Chargebee but an error is thrown.
        // We could use a catch but cannot depend on this error handling to tell if there is an instance set up or not.
        window.Chargebee.init({
          site,
          enableGTMTracking: true,
          isItemsModel: true
        });
        // This function is used to rebind all the events attached. Use this function if a new element inserted dynamically after page load.
        // Needed to register the site change to bind button to that site
        window.Chargebee.registerAgain();
      } catch (error) {
        console.log("Unable to register Chargebee", error);
      }
    } else {
      console.log("Chargebee not loaded");
    }
  });

  return (
    <>
      <button
        className={`button ${active ? "active" : ""} ${size || "normal"} ${
          props.className || ""
        }`}
        // Chargebee set up for checkout addon
        data-cb-type="checkout"
        data-cb-item-0={addonId}
        data-cb-item-0-quantity="1"
      >
        {children}
      </button>
    </>
  );
};
